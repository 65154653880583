/**
 * Transfroms an ancillary insurance offer from a given booked offer (from a booking)
 *
 * @param offer The offer containing the ancillary
 * @returns The ancillary insurance offer
 */
export function transformBookingOfferAncillaryInsurance(offer: ApiSchema['BookedOffer']): OfferAncillary | undefined {
    const part = offer.ancillaries?.[0];
    if (part) {
        return {
            offerId: offer.offerId,
            offerRef: part.type,
            price: part.price,
            type: 'insurance',
            name: part.summary ?? '',
            info: undefined,
        };
    }
    return undefined;
}
