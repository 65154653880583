import { transformBookingOffersAncillary } from '~core/api/transformers/booking/bookingOffersAncillary/transformBookingOffersAncillary';
import { transformBookingProducts } from '~core/api/transformers/booking/bookingProducts/transformBookingProducts';
import { transformBooking } from '~core/api/transformers/booking/transformBooking';

interface ResultType {
    booking: Booking | undefined;
    offersAncillary: OfferAncillary[];
    products: Products;
    vouchers: Voucher[];
    termsAndConditions: ApiSchema['TermsAndCondition'][];
}
type ApiResponseType = ApiSchema['BookingResponse'];
type ParamsType = object;

/**
 * Loads a booking by its id.
 *
 * Based on the type of booking, the result will re-create all its details
 * (passengers, products with offers, vouchers).
 */
export default async function apiLoadBooking(id: string): Promise<ApiResult<ResultType>> {
    try {
        return await callApiGet<ResultType, ApiResponseType, ParamsType>(
            `/bookings/${id}`,

            // Request Params
            {},

            // Transform result
            (response: ApiResponseType) => {
                const booking = transformBooking(response);
                const products = transformBookingProducts(response);
                const offersAncillary = transformBookingOffersAncillary(response);
                const vouchers = transformVouchers(response);
                const termsAndConditions = transformTermsAndConditions(response);

                return {
                    booking,
                    products,
                    offersAncillary,
                    vouchers,
                    termsAndConditions,
                } satisfies ResultType;
            },
        );
    }
    catch (error: any) {
        if (error.status === 404 || error.status === 400) {
            showError({
                fatal: true,
                statusCode: 404,
                statusMessage: 'Booking not found',
            });
        }
        else {
            handleError(error, { id });
        }

        return {
            ok: false,
            warnings: [],
            data: {
                booking: undefined,
                offersAncillary: [],
                products: {},
                vouchers: [],
                termsAndConditions: [],
            } satisfies ResultType,
        };
    }
}

/**
 *
 */
function transformVouchers(response: ApiResponseType) {
    return response.booking?.paymentMethods?.filter(pm => pm.type === 'VOUCHER').map<Voucher>(v => ({
        issuer: v.provider ?? '',
        code: v.voucherInformation?.code ?? '',
        amount: v.amount ?? { amount: 0, currency: 'CHF' },
    })) ?? [];
}

/**
 *
 */
function transformTermsAndConditions(response: ApiResponseType) {
    return response.booking?.termsAndConditions ?? [];
}
