/**
 * Gets all ancillary offers from the booking response
 *
 * @param response The full api response
 * @returns all ancillary offers
 */
export function transformBookingOffersAncillary(response: ApiSchema['BookingResponse']): OfferAncillary[] {
    const bookedOffers = response.booking?.bookedOffers;

    return bookedOffers?.reduce<OfferAncillary[]>((acc, offer) => {
        // If the offer is insurance
        if (offer.ancillaries?.[0]?.type === 'INSURANCE') {
            const offerAncillary = transformBookingOfferAncillaryInsurance(offer);
            add(acc, offerAncillary);
        }

        return acc;
    }, []) ?? [];
}

/**
 *
 */
function add(acc: OfferAncillary[], offerAncillary: OfferAncillary | undefined) {
    if (!offerAncillary) {
        return;
    }
    acc.push(offerAncillary);
}
